"use client";

import posthog from "posthog-js";
import { PostHogProvider as PHProvider, usePostHog } from "posthog-js/react";
import React, { ReactNode, Suspense, useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { clientEnv } from "@/env/client-env";

export function PostHogProvider({ children }: { children: ReactNode }) {
  useEffect(() => {
    if (!clientEnv.postHogKey) {
      return;
    }
    posthog.init(clientEnv.postHogKey, {
      api_host: clientEnv.postHogHost,
      capture_pageview: false, // We capture pageviews manually
      capture_pageleave: true, // Enable pageleave capture
      capture_exceptions: true,
      enable_recording_console_log: true,
      debug: true,
    });
  }, []);

  if (!clientEnv.postHogKey) {
    return children;
  }

  return (
    <PHProvider client={posthog}>
      <SuspendedPostHogPageView />
      {children}
    </PHProvider>
  );
}

function PostHogPageView() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();

  useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname;
      const search = searchParams?.toString();
      if (search) {
        url += "?" + search;
      }
      posthog.capture("$pageview", { $current_url: url });
    }
  }, [pathname, searchParams, posthog]);

  return null;
}

function SuspendedPostHogPageView() {
  return (
    <Suspense fallback={null}>
      <PostHogPageView />
    </Suspense>
  );
}
