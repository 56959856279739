"use client";

import { createContext, ReactNode, useContext } from "react";
import { Flavor } from "@/modules/flavor/actions/get-flavor";

const FlavorContext = createContext<Flavor | undefined>(undefined);

export const FlavorProvider = ({
  data,
  children,
}: Readonly<{
  data: Flavor;
  children: ReactNode;
}>) => {
  return <FlavorContext.Provider value={data}>{children}</FlavorContext.Provider>;
};

export function useFlavor() {
  const data = useContext(FlavorContext);
  if (data === undefined) throw new Error("Missing FlavorContext.");
  return data;
}
