"use client";

import * as React from "react";
import * as ToastPrimitives from "@radix-ui/react-toast";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { Close } from "@mui/icons-material";

const ToastProvider = ToastPrimitives.Provider;

export type ToastViewportProps = React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport> & {
  ref?: React.Ref<typeof ToastPrimitives.Viewport>;
};

const ToastViewport: React.FC<ToastViewportProps> = ({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      "fixed right-0 top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:flex-col md:max-w-[420px]",
      className,
    )}
    {...props}
  />
);

const toastVariants = cva(
  "group font-light pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden p-6 pr-8 transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full",
  {
    variants: {
      variant: {
        info: "bg-info text-info-foreground",
        success: "bg-success text-success-foreground",
        destructive: "destructive group bg-destructive text-destructive-foreground",
      },
    },
    defaultVariants: {
      variant: "info",
    },
  },
);

export type ToastsProps = React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> & {
  ref?: React.Ref<typeof ToastPrimitives.Root>;
} & VariantProps<typeof toastVariants>;

const Toast: React.FC<ToastsProps> = ({ className, variant, children, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      className={cn(toastVariants({ variant }), className, "relative")}
      {...props}
    >
      <ToastPrimitives.Close className="absolute inset-0" />
      {children}
    </ToastPrimitives.Root>
  );
};

export type ToastActionProps = React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action> & {
  ref?: React.Ref<typeof ToastPrimitives.Action>;
};

const ToastAction: React.FC<ToastActionProps> = ({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      "group-[.destructive]:hover:border-destructive/30 group-[.destructive]:border-muted/40 inline-flex h-8 shrink-0 items-center justify-center rounded-md border bg-transparent px-3 text-sm font-medium ring-offset-background transition-colors hover:bg-secondary-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group-[.destructive]:hover:bg-destructive group-[.destructive]:hover:text-destructive-foreground group-[.destructive]:focus:ring-destructive",
      className,
    )}
    {...props}
  />
);

export type ToastCloseProps = React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close> & {
  ref?: React.Ref<typeof ToastPrimitives.Close>;
};

const ToastClose: React.FC<ToastCloseProps> = ({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      "text-foreground/50 absolute right-2 top-2 rounded-md p-1 opacity-0 transition-opacity hover:text-foreground focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600",
      className,
    )}
    toast-close=""
    {...props}
  >
    <Close fontSize="small" />
  </ToastPrimitives.Close>
);

export type ToastTitleProps = React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title> & {
  ref?: React.Ref<typeof ToastPrimitives.Title>;
} & VariantProps<typeof toastVariants>;

const ToastTitle: React.FC<ToastTitleProps> = ({ className, ...props }, ref) => (
  <ToastPrimitives.Title ref={ref} className={cn("text-sm font-semibold", className)} {...props} />
);

export type ToastDescriptionProps = React.ComponentPropsWithoutRef<
  typeof ToastPrimitives.Description
> & {
  ref?: React.Ref<typeof ToastPrimitives.Description>;
};

const ToastDescription: React.FC<ToastDescriptionProps> = ({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn("text-sm opacity-90", className)}
    {...props}
  />
);

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
};
