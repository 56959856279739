"use client";

import { QueryProvider } from "@/common/providers/query-provider";
import React from "react";
import { Toaster } from "@/components/ui/toaster";
import { ThemeProvider } from "@/common/providers/theme-provider";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { KIconThemeProvider } from "@/components/k-icon";
import { Flavor } from "@/modules/flavor/actions/get-flavor";
import { FlavorProvider } from "@/modules/flavor/providers/flavor-provider";
import { PostHogProvider } from "@/components/PostHogProvider";

export function Providers({
  flavor,
  children,
}: Readonly<{
  flavor: Flavor;
  children: React.ReactNode;
}>) {
  return (
    <QueryProvider>
      <FlavorProvider data={flavor}>
        <ThemeProvider
          attribute="class"
          defaultTheme="light"
          enableSystem
          disableTransitionOnChange
        >
          <KIconThemeProvider>
            <TooltipProvider>
              <PostHogProvider>{children}</PostHogProvider>
            </TooltipProvider>
            <Toaster />
          </KIconThemeProvider>
        </ThemeProvider>
      </FlavorProvider>
    </QueryProvider>
  );
}
