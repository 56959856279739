import { cn } from "@/lib/utils";
import { ReactNode } from "react";
import { createTheme, ThemeProvider } from "@mui/material";

export function KIconThemeProvider({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider
      theme={createTheme({
        components: {
          MuiSvgIcon: {
            defaultProps: {
              fontSize: "inherit",
            },
          },
        },
      })}
    >
      {children}
    </ThemeProvider>
  );
}

export function KIcon({
  className,
  size = "md",
  icon,
}: {
  className?: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "4xl";
  icon: ReactNode;
}) {
  return (
    <span
      className={cn(
        className,
        size === "4xl" && "text-7xl",
        size === "xl" && "text-4xl",
        size === "lg" && "text-3xl",
        size === "md" && "text-2xl",
        size === "sm" && "text-lg",
        size === "xs" && "text-xs",
        "inline-flex items-center justify-center",
      )}
    >
      {icon}
    </span>
  );
}
