import { z } from "zod";
import { Optional } from "@/common/utils/types";

const clientEnvSchema = z.object({
  // NOTE: add here client env variables
  postHogKey: z.string().optional(),
  postHogHost: z.string().optional(),
});

const rawClientEnv: Optional<z.input<typeof clientEnvSchema>> = {
  postHogKey: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  postHogHost: process.env.NEXT_PUBLIC_POSTHOG_HOST,
};

export const clientEnv: z.output<typeof clientEnvSchema> = clientEnvSchema.parse(rawClientEnv);
