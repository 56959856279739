// In Next.js, this file would be called: app/providers.jsx
"use client";
// We can not useState or useRef in a server component, which is why we are
// extracting this part out into its own file with 'use client' on top
import { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import posthog from "posthog-js";

function makeQueryClient() {
  const isDevelopment = process.env.NODE_ENV === "development";
  function throwOnError(error: Error) {
    if (isDevelopment) {
      console.error(error);
    } else {
      posthog.captureException(error);
    }
    return false;
  }

  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: isDevelopment ? 0 : undefined,
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid re-fetching immediately on the client
        staleTime: 60 * 1000,
        throwOnError,
      },
      mutations: {
        retry: isDevelopment ? 0 : undefined,
        throwOnError,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
  if (typeof window === "undefined") {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

interface QueryProviderProps {
  children: ReactNode;
}

export function QueryProvider(props: QueryProviderProps) {
  // NOTE: Avoid useState when initializing the query client if you don't
  //       have a suspense boundary between this and the code that may
  //       suspend because React will throw away the client on the initial
  //       render if it suspends and there is no boundary
  const queryClient = getQueryClient();

  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
}
